<template>
  <div>
    <div style="color: #555555;font-weight: 600;padding: 24px 0;margin-top: 16px">收益明细</div>
    <el-table :data="tableData" style="width: 100%" :header-cell-style="headerStyle">
      <el-table-column width="40px"></el-table-column>
      <el-table-column min-width="110" prop="title" label="日期"></el-table-column>
      <el-table-column min-width="70" prop="songNum" label="歌曲数" v-slot="{row}">
        {{row.songNum}}首
      </el-table-column>
      <el-table-column min-width="30" prop="totalMoney" label="收益(元)" v-slot="{row}">
        {{ row.totalMoney.toFixed(2) }}
        <el-link style="color: #ff004d" @click="handleDetail(row.id)">详情</el-link>
      </el-table-column>
    </el-table>
    <SongDialogTable ref="SongDialogTable"></SongDialogTable>
  </div>
</template>

<script>
/**
 * 二级账单表格
 */
import SongDialogTable from './SongDialogTable'
export default {
  components:{
    SongDialogTable
  },
  name: "IncomeDetailTable",
  props: {
    tableData:  Array
  },
  data(){
    return {
     headerStyle:{
       fontSize: '14px',
    fontFamily: 'PingFang SC-Medium',
    fontWeight: 500,
    color: '#555555',
       background: '#f8f8f8'
     }
    }
  },
  methods: {
    handleDetail(val){
      this.$refs.SongDialogTable.dialogVisible=true;
      this.$refs.SongDialogTable.id=val;
    }
  }
}
</script>

<style scoped>

</style>
