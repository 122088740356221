<template>
    <div>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
        <el-breadcrumb-item to="/cash-out-list">累计收益</el-breadcrumb-item>
        <el-breadcrumb-item>账单详情</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider class="div_der"></el-divider>
      <div class="nav_title"><img :src="image1"/>{{ details }}</div>
      <div class="navbar">
        <div class="rightTips" :style="activeList[withdrawalState]">
          <img :src="image2"/>
          {{ details }}
        </div>
        <p class="title">
          {{ appIncomeDetailsEntity.title }}
        </p>
        <div class="money">
          <div class="money_left">
            <span class="money_num">
              {{ $toFindexFn(appIncomeDetailsEntity.totalMoney) }}
            </span>
            <span>元</span>
          </div>
          <div class="money_right">
            (抵扣成本: {{ $toFindexFn(appIncomeDetailsEntity.cost) }} 抵扣预付:
            {{ $toFindexFn(appIncomeDetailsEntity.deductAmount) }})
          </div>
        </div>
        <div class="footer">
          <ul class="nav_footer">
            <li><span class="label">账单分类</span><span class="content">{{ typefil(appIncomeDetailsEntity.type) }}</span>
            </li>
            <li><span class="label">付款公司</span><span class="content">{{ appIncomeDetailsEntity.paymentCompany }}</span>
            </li>
            <li><span class="label">账单编号</span><span class="content">{{ appIncomeDetailsEntity.id }}</span></li>
            <li><span class="label">创建时间</span><span
                class="content">{{ $timestampToString(appIncomeDetailsEntity.createTime) }}</span></li>
            <li><span class="label">入账时间</span><span
                class="content">{{ $timestampToString(appIncomeDetailsEntity.confirmTime) }}</span></li>
          </ul>
          <el-button v-if="withdrawalState===1 && appIncomeDetailsEntity.totalMoney" @click="$router.push('/cash-out/'+appIncomeDetailsEntity.id)">申请提现
          </el-button>
        </div>
      </div>
      <income-detail-table :table-data="this.list"></income-detail-table>
    </div>
</template>
<script>
/**
 * Bill/:id
 * 账单详情
 */
import IncomeDetailTable from './components/IncomeDetailTable'
import {incomeDetailV2} from "../../api/income";
import {dictInfo} from '../../api/dictionary'

export default {
  name: "Bill",
  components: {
    IncomeDetailTable
  },
  data() {
    return {
      image1: '',
      image2: '',
      details: '',
      withdrawalState: -1,
      activeList: {
        1: 'background:#FFE399;color:#6F4717',
        2:'background:#CFEEFF;color:#1E3B5C',
        3:'background:#D4FAD7;color:3f6124;',
        4:'background:#FF3773'
      },
      appIncomeDetailsEntity: {},
      list: []

    }
  },

  created() {
    this.incomeDetailV2_details()
    this.dictInfoData()
  },
  methods: {
    //账单详情
    async incomeDetailV2_details() {
      let res = await incomeDetailV2({id: this.$route.params.id})
      if (res.code === 0) {
        this.appIncomeDetailsEntity = res.data.appIncomeDetailsEntity
        this.list = res.data.list

        this.judge(this.appIncomeDetailsEntity.status)
      }
    },
    judge(val) {
      this.withdrawalState = val;
      switch (val) {
        case 1:
          this.image1 = require("../../assets/income/CashToBeWithdrawn.png");
          this.image2 = require("../../assets/income/CashToBeWithdrawn-tips.png");
          this.details = '账单待提现'
          break
        case 2:
          this.image1 = require("../../assets/income/WithdrawalInProgress.png");
          this.image2 = require("../../assets/income/WithdrawalInProgress-tips.png");
          this.details = '账单提现中'
          break
        case 3:
          this.image1 = require("../../assets/income/CashWithdrawal.png");
          this.image2 = require("../../assets/income/CashWithdrawal-tips.png");
          this.details = '账单已提现'
          break
        case 4:
          this.image1 = require("../../assets/income/Rescinded.png");
          this.image2 = require("../../assets/income/Rescinded-tips.png");
          this.details = '账单已撤销'
          break
      }
    },
    async dictInfoData() {
      let res = await dictInfo({codes: ['10016']})
      if (res.code === 0) {
        this.dictInfoList = res.data
      }
    },
    typefil(val) {
      if (this.dictInfoList !== undefined) {
        let r = this.dictInfoList.find(item => {
          return item.label === val
        })
        return r.value
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/works.scss";
.div_der {
  height: 1px;
  margin-top: 21px;
  margin-bottom: 40px;
}

.nav_title {
  font-size: 20px;
  font-family: PingFang SC, PingFang SC-Medium, serif;
  font-weight: 500;
  text-align: LEFT;
  color: #333333;
  margin-bottom: 24px;

  > img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-right: 12px;
  }
}

.navbar {
  height: 190px;
  background: #f8f8f8;
  position: relative;
  padding: 23px 30px;
  box-sizing: border-box;
  margin-bottom: 40px;

  > .rightTips {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 140px;
    height: 40px;
    border-bottom-left-radius: 30px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #1e3b5c;
    font-family: PingFang SC, PingFang SC-Regular;
    line-height: 41px;

    > img {
      vertical-align: middle;
      width: 24px;
      height: 24px;
    }
  }

  > .title {
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #666666;
    padding: 0;
    margin: 0;
  }

  > .money {
    margin-bottom: 30px;

    .money_left {
      display: inline-block;
      margin-right: 20px;

      span {
        font-size: 14px;
      }

      .money_num {
        display: inline-block;
        font-size: 30px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: LEFT;
        color: #333333;
      }

    }

    .money_right {
      display: inline-block;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #666666;
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;

    .nav_footer {
      padding: 0;
      padding: 0;
      margin: 0;
      list-style: none;
      display: grid;
      grid-row-gap: 14px;
      grid-column-gap: 40px;
      grid-template-columns: 190px 236px 236px;

      li {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        line-height: 14px;

        > .label {
          color: #999999;
          margin-right: 12px;
        }

        > .content {
          color: #666666;
          white-space: nowrap;
        }
      }
    }

    .el-button {
      margin-top: -20px;
      width: 150px;
      height: 44px;
      background: #ff004d;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #ffffff;
      line-height: 14px;
    }
  }
}
</style>
