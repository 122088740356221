/**
 * 收益API
 * http://musicianplatform-api-test.hikoon.com/swagger-ui.html
 */
import request from '@/utils/request'

/**
 * @description 钱包提现数据(点击提现之后的页面数据)------废弃
 */
export const balanceDetail = (data) => request({
  url: `/api/wallet/balanceDetail`,
  method: 'post',
  data,
})

/**
 * @description 钱包账单数据(入账记录)-------废弃
 */
export const billRecord = (data) => request({
  url: `/api/wallet/billRecord`,
  method: 'post',
  data,
})

/**
 * @description 钱包账单渠道详情(入账记录详情)------废弃
 * @param {object} data.billRecordDetailRequest billRecordDetailRequest
 * @param {integer} data.billRecordDetailRequest.channel 渠道: [1:TME 2:网易云 3:海外]
 * @param {integer} data.billRecordDetailRequest.month 日期:年份+月份
 */
export const billRecordDetail = (data) => request({
  url: `/api/wallet/billRecordDetail`,
  method: 'post',
  data,
})

/**
 * @description 提现------废弃
 * @param {object} data.cashOutForm cashOutForm
 * @param {string} data.cashOutForm.bankNo 提现银行卡号
 * @param {number} data.cashOutForm.cashOutMoney 提现金额
 * @param {number} data.cashOutForm.personalTax 个人所得税
 * @param {string} data.cashOutForm.pwd 提现密码(加密)
 */
export const cashOut = (data) => request({
  url: `/api/wallet/cashOut`,
  method: 'post',
  data,
})

/**
 * @description 提现记录----(旧)web使用
 * @param {object} data.cashOutRecordRequest cashOutRecordRequest
 * @param {string} data.cashOutRecordRequest.endTime 结束日期
 * @param {integer} data.cashOutRecordRequest.pageNum 页码
 * @param {integer} data.cashOutRecordRequest.pageSize 每页数量
 * @param {string} data.cashOutRecordRequest.startTime 开始日期
 * @param {integer} data.cashOutRecordRequest.status 提现状态(0待审核;1审核通过;2审核失败)
 */
export const cashOutRecord = (data) => request({
  url: `/api/wallet/cashOutRecord`,
  method: 'post',
  data,
})

/**
 * @description 提现详情---(旧)web使用
 * @param {object} data.cashOutRecordDetailRequest cashOutRecordDetailRequest
 * @param {string} data.cashOutRecordDetailRequest.id 提现单id
 * @param {integer} data.cashOutRecordDetailRequest.pageNum 页码
 * @param {integer} data.cashOutRecordDetailRequest.pageSize 每页数量
 */
export const cashOutRecordDetail = (data) => request({
  url: `/api/wallet/cashOutRecordDetail`,
  method: 'post',
  data,
})

/**
 * @description 提现详情---(新)app使用
 * @param {object} data.cashOutRecordDetailV2Request cashOutRecordDetailV2Request
 * @param {string} data.cashOutRecordDetailV2Request.id 提现单id
 */
export const cashOutRecordDetailV2 = (data) => request({
  url: `/api/wallet/cashOutRecordDetailV2`,
  method: 'post',
  data,
})

/**
 * @description 提现记录----(新)app使用
 * @param {{name: *, pageSize: *, time: *, type: *, pageNum: number, status: *}} data cashOutRecordV2Request
 * @param {string} data.name 公司名称
 * @param {integer} data.pageNum 页码
 * @param {integer} data.pageSize 每页数量
 * @param {string} data.status 提现状态(0提现中;1提现成功;2提现失败)
 * @param {string} data.time 账单日期---格式:2020-08 yyyy-MM 为空查所有
 * @param {string} data.type 账单类型（后台字典类型10016）：1001601账单分成 1001602版税分成  1001603词曲收入 1001604其他
 */
export const cashOutRecordV2 = (data) => request({
  url: `/api/wallet/cashOutRecordV2`,
  method: 'post',
  data,
})

/**
 * @description 钱包首页数据(包含收益统计)
 */
export const detailWallet = (data) => request({
  url: `/api/wallet/detail`,
  method: 'post',
  data,
})

/**
 * @description 账单详情-子账单下的歌曲收益---(新)app使用
 * @param  data incomeDetailRequest
 * @param  data.id 子账单Id
 * @param  data.pageNum 页码
 * @param  data.pageSize 每页数量
 */
export const detailToSongIncome = (data) => request({
  url: `/api/wallet/detailToSongIncome`,
  method: 'post',
  data,
})

/**
 * @description 账单中的公司抬头信息
 * @param {string} params.id id
 */
export const getRiseByIncome = (data, params) => request({
  url: `/api/wallet/getRiseByIncome`,
  method: 'post',
  data, params,
})

/**
 * @description 钱包-账单详情---(旧)web使用
 * @param {object} data.incomeDetailRequest incomeDetailRequest
 * @param {string} data.incomeDetailRequest.id 子账单Id
 * @param {integer} data.incomeDetailRequest.pageNum 页码
 * @param {integer} data.incomeDetailRequest.pageSize 每页数量
 */
export const incomeDetail = (data) => request({
  url: `/api/wallet/incomeDetail`,
  method: 'post',
  data,
})

/**
 * @description 钱包-账单确认
 * @param {string} params.ids ids
 */
export const incomeDetailCheck = (data, params) => request({
  url: `/api/wallet/incomeDetailCheck`,
  method: 'post',
  data, params,
})

/**
 * @description 钱包-账单详情---(新)app使用
 * @param {object} data.incomeDetailV2Request incomeDetailV2Request
 * @param {string} data.incomeDetailV2Request.id 账单Id
 */
export const incomeDetailV2 = (data) => request({
  url: `/api/wallet/incomeDetailV2`,
  method: 'post',
  data,
})

/**
 * @description 钱包-账单列表
 * @param {{name: *, pageSize: number, startTime: *, endTime: *, time: *, type: *, pageNum: number, status: *}} data incomeListRequest
 * @param {string} data.endTime 账单结束日期
 * @param {string} data.name 公司名称
 * @param {integer} data.pageNum 页码
 * @param {integer} data.pageSize 每页数量
 * @param {string} data.startTime 账单开始日期
 * @param {string} data.status 0 未确认 1已确认 2提现中 3已提现 4.已撤销 不传查所有
 * @param {string} data.time 账单日期---格式:2020-08 yyyy-MM 为空查所有
 * @param {string} data.type 账单类型（后台字典类型10016）：1001601账单分成 1001602版税分成  1001603词曲收入 1001604其他
 */
export const incomeList = (data) => request({
  url: `/api/wallet/incomeList`,
  method: 'post',
  data,
})

/**
 * @description 钱包-可提现金额以及账单数据，账单分页，但总可提现金额是所有账单累计---(旧)web使用
 * @param {integer} params.pageSize 每页数量
 * @param {integer} params.pageNum 页码
 */
export const moneyAndIncomeDetail = (data, params) => request({
  url: `/api/wallet/moneyAndIncomeDetail`,
  method: 'post',
  data, params,
})

/**
 * @description 我的银行卡数据(登录机构返回机构银行卡数据)
 */
export const myBankMsg = (type) => request({
  url: `/api/wallet/myBankMsg?type=${type}`,
  method: 'post',
})

/**
 * @description 我的付款公司
 */
export const myPaymentCompany = (data) => request({
  url: `/api/wallet/myPaymentCompany`,
  method: 'post',
  data,
})

/**
 * @description 银行卡新增/修改
 * @param {object} data.bankCardForm bankCardForm
 * @param {string} data.bankCardForm.bankAddress 支行地址
 * @param {string} data.bankCardForm.bankCardNum 银行卡号
 * @param {string} data.bankCardForm.bankName 开户银行
 * @param {string} data.bankCardForm.name 账户名
 */
export const payCardUpdate = (data) => request({
  url: `/api/wallet/payCardUpdate`,
  method: 'post',
  data,
})

/**
 * @description 钱包密码新增/修改
 * @param {object} data.payPwdForm payPwdForm
 * @param {string} data.newPwd 新密码
 * @param {string} data.oldPwd 原密码(初始设置密码不传)
 */
export const payPwdUpdate = (data) => request({
  url: `/api/wallet/payPwdUpdate`,
  method: 'post',
  data,
})

/**
 * @description 钱包-已确认账单-打款公司数据---(旧)web使用
 * @param {object} data.paymentCompanyDataRequest paymentCompanyDataRequest
 * @param {integer} data.paymentCompanyDataRequest.pageNum 页码
 * @param {integer} data.paymentCompanyDataRequest.pageSize 每页数量
 * @param {string} data.paymentCompanyDataRequest.type 账单类型（后台字典类型10016）：1001601账单分成 1001602版税分成  1001603词曲收入 1001604其他。以后新增和编辑维护后台字典 (为空查所有)
 */
export const paymentCompanyData = (data) => request({
  url: `/api/wallet/paymentCompanyData`,
  method: 'post',
  data,
})

/**
 * @description 待提现列表---(新)app使用
 * @param {{pageSize: number, type, pageNum: number}} data paymentCompanyDataRequest
 * @param {integer} data.pageNum 页码
 * @param {integer} data.pageSize 每页数量
 * @param {string} data.type 账单类型（后台字典类型10016）：1001601账单分成 1001602版税分成  1001603词曲收入 1001604其他。以后新增和编辑维护后台字典 (为空查所有)
 */
export const readyWithdrawList = (data) => request({
  url: `/api/wallet/readyWithdrawList`,
  method: 'post',
  data,
})

/**
 * @description 钱包-提现-账单统计数据---(旧)
 * @param {object} data.statisticsRequest statisticsRequest
 * @param {integer} data.statisticsRequest.dimension 0:公司维度统计 1:分类来源分类统计
 * @param {string} data.statisticsRequest.time 账单日期---格式:2020-08 yyyy-MM 为空查所有
 * @param {integer} data.statisticsRequest.type 0:显示可提现收入 1:显示所有收入
 */
export const statisticsByCompany = (data) => request({
  url: `/api/wallet/statisticsByCompany`,
  method: 'post',
  data,
})

/**
 * @description 钱包-账单统计数据2-根据时间查询(收益类型饼图总计)
 * @param {object} data.statisticsRequest statisticsRequest
 * @param {integer} data.statisticsRequest.timeValue 0近三个月 1近半年 2近一年
 */
export const statisticsByTime = (data) => request({
  url: `/api/wallet/statisticsByTime`,
  method: 'post',
  data,
})

/**
 * @description 钱包-账单统计数据2-根据时间查询(收益类型线状图)
 * @param {object} data.statisticsRequest statisticsRequest
 * @param {integer} data.statisticsRequest.timeValue 0近三个月 1近半年 2近一年
 * @param {string} data.statisticsRequest.type 账单类型（后台字典类型10016）：1001601账单分成 1001602版税分成  1001603词曲收入 1001604其他
 */
export const statisticsByTimeAndType = (data) => request({
  url: `/api/wallet/statisticsByTimeAndType`,
  method: 'post',
  data,
})

/**
 * @description 提现---(旧)
 * @param {object} data.withdrawRequest withdrawRequest
 * @param {array} data.withdrawRequest.list 公司列表
 * @param {number} data.withdrawRequest.money 可提现金额,用于与接口校对金额是否一致
 * @param {string} data.withdrawRequest.pwd 提现密码md5
 * @param {number} data.withdrawRequest.tax 个人所得税
 */
export const withdraw = (data) => request({
  url: `/api/wallet/withdraw`,
  method: 'post',
  data,
})

/**
 * @description  提现状态变更--分贝后台调用
 * @param {object} data.withdrawStatusChangeRequest withdrawStatusChangeRequest
 * @param {string} data.withdrawStatusChangeRequest.desp 审核失败原因
 * @param {integer} data.withdrawStatusChangeRequest.id 提现单id
 * @param {integer} data.withdrawStatusChangeRequest.state 1 审核通过;2审核失败
 */
export const withdrawStatusChange = (data) => request({
  url: `/api/wallet/withdrawStatusChange`,
  method: 'post',
  data,
})

/**
 * @description 提现---(新)
 * @param {object} data.withdrawV2Request withdrawV2Request
 * @param {string} data.withdrawV2Request.id 一级账单id
 * @param {number} data.withdrawV2Request.money 可提现金额,用于与接口校对金额是否一致
 * @param {string} data.withdrawV2Request.pwd 提现密码md5
 * @param {number} data.withdrawV2Request.tax 个人所得税
 * @param {array} data.withdrawV2Request.withdrawRequestV2VoList 二级账单开票列表
 */
export const withdrawV2 = (data) => request({
  url: `/api/wallet/withdrawV2`,
  method: 'post',
  data,
})

//意见反馈
export const feedBack_submit =(data)=>request({
  url:'/feedBack/submit',
  method:'post',
  data,
})
