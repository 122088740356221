<template>
  <el-dialog
      title="歌曲收益详情"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      center
  >
    <el-table :data="gridData" :row-style="rowStyle" :header-cell-style="headerStyle">
      <el-table-column property="name" label="歌曲名称" ></el-table-column>
      <el-table-column  label="权益" >
        <template slot-scope="scope">
          {{scope.row.role.join(',')}}
        </template>
      </el-table-column>
      <el-table-column label="核销成本(元)">
             <template slot-scope="scope">
               {{$toFindexFn(scope.row.cost)}}
             </template>
      </el-table-column>
      <el-table-column property="deductAmount"  label="核销预付(元)" >
        <template slot-scope="scope">
          {{$toFindexFn(scope.row.deductAmount)}}
        </template>
      </el-table-column>
      <el-table-column property="realShareAmount" label="收益(元)">
        <template slot-scope="scope">
          {{$toFindexFn(scope.row.realShareAmount)}}
        </template>
      </el-table-column>
    </el-table>
    <div class="footer-pagination">
      <el-pagination
          hide-on-single-page
          v-on:current-change="currentChangefn"
          background
          layout="prev, pager, next,slot"
          :current-page="currentPage"
          :page-size="5"
          :total="totalNum">
        <div class="slot_shell">
          <ul class="el-paginetion-slot">
            <li class="page">共 {{pageCount}} 页</li>
            <li>到第<el-input v-model="currentPageVal"></el-input></li>
            <li>
              <el-button @click="jumperfn()">确定</el-button>
            </li>
          </ul>
        </div>
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script>
/**
 * 二级账单的歌曲列表弹窗
 */
import {detailToSongIncome} from '../../../api/income'
//当前页数
let PAGE_SIZE=5;
export default {
  name: "SongDialogTable",
  data() {
    return {
      dialogVisible: false,
       id:'',
      gridData: [],
      currentPage:1,
      currentPageVal:1,
    //总条数
      totalNum:0,
      //总页数
      pageCount:0,
    }
  },

  methods: {
    //表格头部样式
    headerStyle() {
      let style = {
        color: '#555555;',
        background: '#f8f8f8',
        fontWeight: 500,
        fontSize: '13px'
      }
      return style
    },
  //内容样式
    rowStyle(){
      let style={
        fontSize: '13px',
        fontFamily: 'PingFang SC-Medium;',
        fontWeight: 400,
        color: '#666666;',
      }
      return style
    },
    //分页
// currentPage 改变时会触发
    currentChangefn(val){
      this.currentPageVal=val;
      this.currentPage=val;
      this.talbeListfn()
    },
    //跳转到那一页
    jumperfn(){
      this.currentPage=Number(this.currentPageVal);
      this.talbeListfn()
    },
  //获取表格数据
    talbeListfn(){
      let p={
        id:this.id,
        pageNum:this.currentPage,
        pageSize:PAGE_SIZE,
      }
      detailToSongIncome(p).then(res=>{
         if(res.code === 0 && this.data !==null){
            this.gridData = res.data.list;
            this.totalNum = res.data.total;
            this.pageCount = res.data.totalPage;

         }else {
           this.gridData = [];
           this.totalNum =  0
           this.pageCount = 0;
         }
      })
    }
  },
  watch:{
    dialogVisible(newval){
      if(newval){
        this.talbeListfn()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  padding: 30px 40px;
  width: 840px;
  //height: 580px;
  background: #ffffff;
  box-sizing: border-box;

  .el-dialog__header {
    padding: 0 !important;
    margin-bottom: 30px;
    .el-dialog__title{
      font-size: 18px;
      font-weight: 500;
      color: #555555;
      font-family: PingFang SC-Medium;
    }
  }

  .el-dialog__body {
    padding: 0 !important;
  }

}
.footer-pagination {
 ::v-deep .el-pagination {
   .el-pager{
     li{
       background: #ffffff;
       border: 1px solid #e1e0e0;
       border-radius: 2px;
       margin: 0 2.5px;
       font-size: 12px;
       font-family: PingFang SC, PingFang SC-Regular;
       font-weight: 400;
       color: #666666;
     }
   }

   button{
     background: #ffffff;
     border: 1px solid #e1e0e0;
     border-radius: 2px;
   }
  }
 margin-top:24px;
  display: flex;
  justify-content: center;
  .slot_shell{
    display: inline-block;
    .el-paginetion-slot{
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      list-style: none;
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: CENTER;
      color: #666666;
      li{
        margin-left: 6px;
      }
      .page{
        margin-right: 17px;
      }
      ::v-deep .el-input{
        width: 30px;
        margin-left: 8px;
        .el-input__inner{
          height: 30px;
          border: 1px solid #e1e0e0;
        }
      }
      .el-button{
        width: 48px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #e1e0e0;
        border-radius: 2px;
        color: #666666;
      }
    }
  }


}

</style>
