/**
 * 字典API
 * http://musicianplatform-api-test.hikoon.com/swagger-ui.html
 */
import request from '@/utils/request'

/**
 * @description 指定字典查询
 * @param {object} data request
 * @param {array<string>} data.codes 父节点编码，可通过后台数据字典查询一级编码
 1.意见反馈常见内容:10013
 2.银行列表:10014
 3.音乐发行平台：10015
 4.资讯类型: 11001 (busiType:1网格 2横向 3列表; busiType2:0首页不显示 1首页显示)
 更多编码请登录后台数据字典查看
 */
export const dictInfo = (data) => request({
  url: `/api/dict/dictInfo`,
  method: 'post',
  data,
})
